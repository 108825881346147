<template>
	<div>
		<v-row dense>
			<v-col cols="12">
					<v-data-table
						class="ma-3 medical-staff-data-table"
						v-if="filteredUsers"
						:loading="loading"
						:headers="headers"
						:headers-length="headers.length"
						:no-data-text="$t('no-data-available')"
						:loading-text="$t('loading')"
						:search="appBarSearch"
						:items="filteredUsers"
						:items-per-page="-1"
						sort-by="id"
						item-key="id"
						fixed-header
						dense
						:height="pageHeight(145)"
						:single-expand="true"
						:expanded.sync="expanded"
						:footer-props="{
							showCurrentPage: false,
							showFirstLastPage: false,
							itemsPerPageOptions: [filteredUsers.length],
							itemsPerPageText: null
						}"
					>
						<template v-slot:item="{ item }" >
							<tr @click="editDrawerDetails(item)" >
								<td>
									<div class="table-cols">
<!--										<div class="image-container">-->
<!--										<img class="rounded-image" v-if="item.roleId === 4" :src="nurse_icon(item.status)" height="24px">-->
<!--										<img class="rounded-image" v-else-if="item.roleId === 3" :src="doctor_icon(item.status)" color="primary" height="24px">-->
<!--										<img class="rounded-image" v-else-if="item.roleId === 7" :src="nurse_icon(item.status)" color="primary" height="24px">-->
<!--										</div>-->
										<div class="text-container table-items-small py-1 ml-n2">
										<span class="staff-name">{{ item.fullName }}</span>
                      <span class="staff-name">{{ $i18n?.locale === 'en' ? item.roleData.roleNameForDisplay : item.roleData.roleNameForDisplayESP }}</span>
                      <!-- <span class="profession">Doctor</span> -->
<!--										<span v-if="item.roleId === 4"><img  class="role-icon" :src="nurse_black"  height="16px"> {{$t('nurse')}}</span>-->
<!--										<span v-else-if="item.roleId === 3"><img class="role-icon" :src="doctor_icon(item.status)" color="primary" height="16px"> {{$t('doctor')}}</span>-->
<!--										<span v-else-if="item.roleId === 7"><img class="role-icon" :src="nurse_icon(item.status)" color="primary" height="16px"> {{$t('nurse')}}</span>-->
										</div>
									</div>
								</td>
								<td>
									<div class="table-cols2" v-if="item.role === 'Doctor'">
										<v-chip-group column v-if="item.doctorMetadataReadDto">
                        <v-chip label small class="my-1 mr-1 px-1 text-color-white" color="chips" v-for="spec in item.doctorMetadataReadDto.spetializationData" :key="spec.spetializationId">
                          {{ getSpecializationDescription(spec) }}
                        </v-chip>
										</v-chip-group>
<!--										<span class="table-items-small d-flex align-center" style="" v-else>{{$t('remote-care')}}</span>-->
									</div>
								</td>
								<td v-if="roleName === 'ORGANIZATION_ADMIN'">
									<div class="table-cols2">
										<v-chip-group column v-if="item.hospitalsAssignedData">
											<v-chip label small class="my-1 mr-1 px-1 my-1 mr-1 px-1 text-color-white" color="primary2" v-for="assignedHospital in item.hospitalsAssignedData" :key="assignedHospital.id">
												{{ assignedHospital.hospitalName }}
											</v-chip>
										</v-chip-group>
										<span class="table-items-small d-flex align-center" style="" v-else>{{$t('remote-care')}}</span>
									</div>
								</td>
								<td v-else class="table-cols-2">
									<div class="table-cols2" >
										<!-- <p class="staff-office" style="margin:0px">{{ hospitalData !== null ? hospitalData?.hospitalName : '' }}</p> -->
										<span v-if="hospitalData !== null">{{  hospitalData?.hospitalName }}</span>
									</div>
								</td>
								<td>
									<div class="table-cols2">
										<span class="">{{ item.email }}</span>
									</div>
								</td>
								<td>
									<div class="table-cols3">
										<span>{{ item.phone }}</span>
									</div>
								</td>
								<td>
									<v-icon class="mr-4"	small :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
								</td>
								<td>
                  <div class="d-flex align-center justify-center">
									<v-menu
										left
										bottom
										class="py-0"
										offset-y
										transition="slide-x-transition"
									>
										<template v-slot:activator="{on, attrs}">
											<v-btn
												icon
												color="black"
												v-bind="attrs"
												v-on="on"
											>
												<span class="mdi mdi-24px mdi-dots-horizontal  "></span>
											</v-btn>
										</template >
										<v-list class="px-0" dense >
											<!-- <v-list-item link dense @click="synchronizeDrawers(items[0].patientId)"> -->
											<v-list-item class="py-0 my-0" link dense @click="editDrawerDetails(item)">
												<v-list-item-icon class="mx-1 ">
												<v-icon class="icon-img mdi-18px pt-1" color="black">mdi-eye</v-icon>
												</v-list-item-icon>
												<v-list-item-content class="font-bold text-body-2">{{$t("view")}}</v-list-item-content>
											</v-list-item>
										</v-list>
									</v-menu>
                  </div>
								</td>
<!--                <td v-if="roleName === 'HOSPITAL_ADMIN'"></td>-->
							</tr>
						</template>
					</v-data-table>
      </v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { AsYouType } from 'libphonenumber-js';
import { medicalTeamWhite, doctorBlack, doctorSecondary, nurseBlack, nurseSecondary, mailPurple, phonePurple } from '@/assets';
export default {
	data () {
		return {
			expanded: [],
			loading: false,
			headerClass: 'black_inner--text !important;',
			search: null,
			doctor_black: doctorBlack,
			doctor_secondary: doctorSecondary,
			nurse_black: nurseBlack,
			nurse_secondary: nurseSecondary,
			medical_team_white: medicalTeamWhite,
			mailPurple: mailPurple,
			phonePurple: phonePurple,
		};
	},
	async mounted () {
		await this.getStaff();
	},
	computed: {
		...mapState({
			relatedUsers: (state) => state.hospitals.users,
			userData: (state) => state.authentication.userData,
			relatedOrganizationMedicalStaff: (state) => state.medicalInstitutions.relatedOrganizationMedicalStaff,
			hospitalData: (state) => state.hospitals.hospitalData,
		}),
		currentLocale () {
			return this.$i18n.locale === 'en' ? 'spetializationDesc' : 'spetializationDescEsp';
		},
		...mapGetters({
			hospitalId: 'authentication/getHospitalId',
			pageHeight: 'pageHeight',
			roleName: 'authentication/getRole',
			delay: 'authentication/getDelay',
			appBarSearch: 'filterbar/getSearch',
			appBarRole: 'filterbar/getRole',
			appBarSpecialization: 'filterbar/getSpecialization',
			medicalStaff: 'hospitals/getMedicalStaff',
			// getHospital: 'authentication/getHospital',
			// getHospitals: 'authentication/getHospitals',
		}),
		editDetailsDrawer () {
			return this.$store.state.viewDetailsDrawer;
		},
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return window.innerHeight - 320;
			case 'sm': return window.innerHeight - 300;
			case 'md': return window.innerHeight - 300;
			case 'lg': return window.innerHeight - 280;
			case 'xl': return window.innerHeight - 280;
			default: return 0;
			}
		},
		headers () {
      let headers = [];
        headers = [
				{ text: this.$t('name'), value: 'fullName', class: 'color-header' },
				{ text: this.$t('specialization'), class: 'color-header' },
				{ text: this.$t('offices'), class: 'color-header' },
				{ text: this.$t('email'), value: 'email', class: 'color-header' },
				{ text: this.$t('phone'), value: 'phone', class: 'color-header' },
				{ text: this.$t('status'), value: 'status', class: 'color-header', align: 'center', style: 'width: 10px; white-space: nowrap;' },
				{ text: this.$t('actions'), value: 'status', class: 'color-header', align: 'center', sortable: false },
        // { text: '', sortable: false },
				{ text: '', value: '', class: 'color-header', sortable: false },
			];
      headers.pop();
      return headers;
		},
		// users () {
		// 	return this.medicalStaff?.filter((ru) => {
		// 		ru.statusDescription = ru.status === 1 ? 'Active' : 'Inactive';
		// 		ru.fullName = ru.firstName.concat(' ', ru.lastName);
		// 		ru.phone = new AsYouType().input(ru.phone);
		// 		ru.specializations = ru?.doctorMetadataReadDto?.spetializationData.map(item => item.spetializationId) ?? [];
		// 		switch (ru.roleId) {
		// 		case 3:
		// 			ru.role = 'Doctor';
		// 			break;
		// 		case 4:
		// 			ru.role = 'Nurse';
		// 			break;
		// 		case 7:
		// 			ru.role = 'Caregiver';
		// 			break;
		// 		default:
		// 			break;
		// 		}
		// 		if (this.appBarSpecialization === -1 || !this.appBarSpecialization) {
		// 			if (this.appBarRole === 0 || !this.appBarRole) {
		// 				return (ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7);
		// 			} else {
		// 				return (ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7) && (ru.roleId === this.appBarRole);
		// 			}
		// 		}	else {
		// 			if (this.appBarRole === 0 || !this.appBarRole) {
		// 				return (ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7) && ru.specializations.includes(this.appBarSpecialization);
		// 			} else {
		// 				return (ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7) && (ru.roleId === this.appBarRole) && ru.specializations.includes(this.appBarSpecialization);
		// 			}
		// 		}
		// 	});
		// },
		filteredUsers () {
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				let calculateFileteredStaff = this.relatedOrganizationMedicalStaff?.filter((ru) => {
													ru.statusDescription = ru.status === 1 ? 'Active' : 'Inactive';
													ru.fullName = ru.firstName.concat(' ', ru.lastName);
													ru.phone = new AsYouType().input(ru.phone);
													ru.specializations = ru?.doctorMetadataReadDto?.spetializationData.map(item => item.spetializationId) ?? [];
													switch (ru.roleId) {
													case 3:
														ru.role = 'Doctor';
														break;
													case 4:
														ru.role = 'Nurse';
														break;
													case 7:
														ru.role = 'Caregiver';
														break;
													default:
														break;
												}
												return ru;
											});

				// filter the result
				if (this.appBarRole !== -99 && this.appBarRole !== null) {
					calculateFileteredStaff = calculateFileteredStaff.filter(u => u.roleId === this.appBarRole);
				}

				if (this.appBarSpecialization !== -1 && this.appBarSpecialization !== null) {
					calculateFileteredStaff = calculateFileteredStaff.filter(u => u.specializations.includes(this.appBarSpecialization));
				}

				return calculateFileteredStaff;
			} else if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				let calculateFileteredStaff = this.medicalStaff?.filter((ru) => {
												if (ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7) {
													ru.statusDescription = ru.status === 1 ? 'Active' : 'Inactive';
													ru.fullName = ru.firstName.concat(' ', ru.lastName);
													ru.phone = new AsYouType().input(ru.phone);
													ru.specializations = ru?.doctorMetadataReadDto?.spetializationData.map(item => item.spetializationId) ?? [];
													switch (ru.roleId) {
														case 3:
															ru.role = 'Doctor';
															break;
														case 4:
															ru.role = 'Nurse';
															break;
														case 7:
															ru.role = 'Caregiver';
															break;
														default:
															break;
													}
													return ru;
												}
										});

				// filter the result
				if (this.appBarRole !== -99 && this.appBarRole !== null) {
					calculateFileteredStaff = calculateFileteredStaff.filter(u => u.roleId === this.appBarRole);
				}

				if (this.appBarSpecialization !== -1 && this.appBarSpecialization !== null) {
					calculateFileteredStaff = calculateFileteredStaff.filter(u => u.specializations.includes(this.appBarSpecialization));
				}

				return calculateFileteredStaff;
			} else {
				return [];
			}
		},
	},
  beforeDestroy () {
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
  },
  methods: {
		getSpecializationDescription (spec) {
			return spec[this.currentLocale];
		},
		async editDrawerDetails (item) {
			this.loading = true;
			if (!this.editDetailsDrawer) {
				await this.$store.dispatch('users/getUserById', item.id);
				// await this.$store.commit('users/GET_USER_BY_ID', item); // TODO: for future use from state
				this.$store.commit('SET_EDIT_DETAILS_DRAWER', item);
        this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
			} else {
				this.$store.commit('SET_EDIT_DETAILS_DRAWER', item);
        this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
			}
			this.loading = false;
		},
		async expand (item) {
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
		async getStaff () {
			this.loading = true;
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				const organizationId = this.userData.organizationId;
				await this.$store
					.dispatch('medicalInstitutions/getRelatedOrganizationMedicalStaff', organizationId)
					.then(() => {
						this.loading = false;
					});
			} else if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				const hospitalId = this.userData.hospitalAssignedData.id;
				await this.$store
					.dispatch('hospitals/getRelatedMedicalStaff', hospitalId)
					.then(() => {
						this.loading = false;
					});
			}
		},
		closeDialog () {
			this.getStaff();
		},
		doctor_icon (item) {
			switch (item) {
			case 1:
				return this.doctor_black;
			default:
				return this.doctor_secondary;
			}
		},
		nurse_icon (item) {
			switch (item) {
			case 1:
				return this.nurse_black;
			default:
				return this.nurse_secondary;
			}
		},
		success (res) {
			this.getStaff();
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'success',
					color: 'main_green',
					message: res.msg,
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'error',
					color: 'main_red',
					message: res.msg,
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		async sendActivationEmail (item) {
			const sendValidationEmail = { email: item.email };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							id: 'addedAdmin',
							type: 'success',
							color: 'main_green',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					} else {
						this.$store.commit('alerts/add', {
							id: 'errorAddingAdmin',
							type: 'error',
							color: 'main_red',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					}
				});
		},
	},
	watch: {
		async appBarMedicalInstitution (val) {
			// after filtering
		},
		async appBarUserStatusVerifier (val) {
			// after filtering
		},
		async appBarRole (val) {
			// after filtering
		},
	},
};
</script>

<style>
/* *{
	border: red solid 1px;
} */

.table-items-small{
	font-size:small;
}
.table-items-medium{
	font-size:medium;
}
.table-cols {
	display: flex;
	align-items: center;
	min-width: 150px;
}
.image-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
.staff-name {
	font-weight: bold;
}

.role-icon{
	vertical-align: text-top;
}
.text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 10px; /* or margin-left: 10px; */
}
.table-cols2{
	display: flex;
	align-items: center;
}
.table-cols3{
	display: flex;
	align-items: center;
}
.staff-office{
	align-items: center;
	min-width: 90px;
}
.staff-email{
	color: #6600FF;
	padding-left: 10px;
}
.staff-phone{
	color: #6600FF;
	padding-left: 0px;
	min-width: 110px;
}
.table-cols2 img,
.table-cols3 img {
	padding:  5px;
}
 .color-header {
	background-color: #ECEAF3 !important;
	color: #090017;
 }

.v-data-table-header tr{
	background-color: red;
}

.v-data-footer{
	justify-content: right;
	margin-right: 0px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.v-data-footer__icons-before .v-btn{
	height: 30px;
	width: 30px;
	margin-right: 0px !important;
}

.v-data-footer__icons-before .v-btn .v-icon{
	font-size: 16px;
}

.v-data-footer__icons-after .v-btn{
	height: 30px;
	width: 30px;
	margin-left: 0px !important;
}

.v-data-footer__icons-after .v-btn .v-icon{
	font-size: 16px;
}
</style>
